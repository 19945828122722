.App {
  margin-left: auto;
  margin-right: auto;
  font-family: 'Rubik', sans-serif;
}

h1 {
  margin-top: 0;
}

h2 {
  text-align: center;
  color: #929292;
  font-size: 36px;
  font-weight: bold;
  margin-top: 90px;
}

p {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  color: #424242;
  line-height: 28px;
}

img {
  display:block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.header {
  text-align: center;
  line-height: 183px;
  background-color: #f6f6f6;
  width: 100%;
  height: 183px;

  color: #38B6FF;
  font-size: 72px;
  font-weight: bold;
}

.footer {
  text-align: center;
  font-size: 10px;
  margin: 150px 0 20px 0;
}

.intro {
  text-align: center;
  font-weight: normal;
  font-size: 20px;
  color: #424242;
  margin-top: 60px;
}